export const state = () => ({
  selectedListingData: null,
  selectedPeriodData: null,
  emptyStatePrime: false,
  totalActiveKosPrime: 0,
  totalPrimeAbleKos: 0,
});

export const getters = {
  isOwnerHaveActiveListing(state) {
    return state.totalActiveKosPrime === 0;
  },
  isOwnerHavePrimeAbleListing(state) {
    return state.totalActiveKosPrime > 0 && state.totalPrimeAbleKos === 0;
  },
};

export const mutations = {
  setSelectedListingData(state, data = null) {
    state.selectedListingData = data;
  },
  setSelectedPeriodData(state, data = null) {
    state.selectedPeriodData = data;
  },
  setEmptyStatePrime(state, data = false) {
    state.emptyStatePrime = data;
  },
  setTotalActiveKosPrime(state, data = 0) {
    state.totalActiveKosPrime = data;
  },
  setTotalPrimeAbleKos(state, data = 0) {
    state.totalPrimeAbleKos = data;
  },
};
