export default {
  getPrimeFaq() {
    return this.get('/pms_api/v3/prime/faq');
  },
  submitPayPrime(payload) {
    return this.post('/pms_api/v3/prime/order', payload);
  },
  getPackagePeriode(subdistrictId, params) {
    return this.get(`/pms_api/v3/prime/package/${subdistrictId}`, { params });
  },
  getPrimeHistoryList(params) {
    return this.get('/pms_api/v3/prime/order', { params });
  },
  getPrimePackagePlacement() {
    return this.get('/pms_api/v3/prime/placement');
  },
  getPrimeKeywords(roomTypeId, params) {
    return this.get(
      `/pms_api/v3/owner/room-type/${roomTypeId}/top-nearby-keyword`,
      { params }
    );
  },
};
